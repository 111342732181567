import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Case from '../components/page-inner/case'
import Layout from '../components/layout'

export const query = graphql`
  query CaseTemplateQuery($id: String!) {
    case: sanityCases(id: { eq: $id }) {
      id
      title
      metaTitle
      metaDescription
      path
      caseSections {
        ... on SanityContentSection {
          _type
          _key
          _rawSectionContent(resolveReferences: { maxDepth: 5 })
          sectionSettings {
            collapsibleSection
            sectionTitle
            sectionIcon {
              iconSource
            }
          }
        }
        ... on SanityOverviewSection {
          _type
          _key
          sanctionTypes {
            _key
            additionalInfo
            sanctionCategory {
              title
            }
          }
          status
          objectives {
            _key
            title
          }
          policyInstrument {
            _key
            title
          }
          regionalContent
          unilateralContent
          sectionSettings {
            collapsibleSection
            sectionTitle
          }
        }
      }
      startDate
      endDate
    }
    relatedEpisodes: allSanityEpisode(
      filter: { inCase: { id: { eq: $id } } }
      sort: { fields: episodeNumber }
    ) {
      totalCount
      nodes {
        id
        episodeNumber
        path
        startDate(formatString: "YYYY")
        endDate(formatString: "YYYY")
      }
    }
  }
`

const CaseTemplate = props => {
  const { data, errors } = props
  const caseData = data && data.case

  const title = caseData.title
  const metaTitle = caseData.metaTitle || title
  const metaDescription = caseData.metaDescription

  return (
    <Layout title={caseData.title} {...props} showEpisodes>
      {errors && <SEO title="GraphQL Error" />}
      {caseData && <SEO title={metaTitle} description={metaDescription} />}

      {errors && <GraphQLErrorList errors={errors} />}

      {caseData && <Case {...caseData} location={props.location} />}
    </Layout>
  )
}

export default CaseTemplate
